import QueryString from "qs"

export type PaginatedData<T> = {
    current_page: number, // 1,
    data: T[], // [],
    first_page_url: string, // "/?page=1",
    from: number, // 1,
    last_page: number, // 17,
    last_page_url: string, // "/?page=17",
    links: any[], // [],
    next_page_url: string | null, // "/?page=2",
    path: string, // "/",
    per_page: number, // 15,
    prev_page_url: string | null, // null,
    to: number, // 15,
    total: number, // 247
}

export type SocialNetwork<IdType> = {
    id: IdType, // 5,
    created_at: string | null, // "2023-01-19T14:43:38.000000Z",
    updated_at: string | null, // "2023-01-19T14:43:38.000000Z",
    name: string, // "Instagram",
    codename: string, // "INSTA"
}

export type WhitelistItem<IdType> = {
    id: IdType, // 6,
    created_at: string | null, // "2023-01-05T23:21:20.000000Z",
    updated_at: string | null, // "2023-01-16T21:05:25.000000Z",
    panel_id: number, // 1,
    service_id: number, // 3937,
    new_rate: string | null, // "100.000000000",
    content: string | null, // "hello world",
    new_name: string | null, // "",
    social_network_id: number | null, // 1,
    social_network: SocialNetwork<number> | null
}

export type Service = {
    service: string, // "3937",
    name: string, // "3937 - 👁 Телеграм Просмотры (любой пост) 🇷🇺⚡🚫",
    type: string, // "Default",
    rate: string, // "6.00",
    min: string, // "20",
    max: string, // "30000",
    dripfeed: boolean, // true,
    refill: boolean, // false,
    cancel: boolean, // true,
    category: string, // "Telegram",
    panel_id: number, // 1,
    whitelist: WhitelistItem<number> | null
}

export type Post<IdType> = {
    id: IdType, // 6,
    created_at: string | null, // "2023-01-12T14:30:11.000000Z",
    updated_at: string | null, // "2023-01-14T11:54:26.000000Z",
    title: string, // "this is the updated title",
    content: string, // "hi world!!!",
    image: string, // "/storage/posts/OIgqeQjunPY7mLF4hsEU5Hz0v1bZIWTWseRgVcdG.png"
}

export type Api<IdType> = {
    id: IdType, // 6,
    created_at: string | null, // "2023-01-12T14:30:11.000000Z",
    updated_at: string | null, // "2023-01-14T11:54:26.000000Z",
    base_url: string, // "https://ru.wos-api.biz",
    api_key: string, // "xxx",
    balance: string | null, // "61.3650000 RUB",
}

export type Order<IdType> = {
    id: IdType, // 34,
    bill_id: string, // "01cc6629-7a81-4c46-bc8c-9205982a30cf",
    created_at: string, // "2023-02-26T16:16:37.000000Z",
    updated_at: string, // "2023-02-26T23:35:01.000000Z",
    user_id: number, // 1,
    panel_id: number, // 1,
    service: string, // "{\"max\": \"400000\", \"min\": \"1000\", \"name\": \"4292 - 👁 Телеграм Просмотры (идут в статистику) 🇷🇺⚡\", \"rate\": \"10.00\", \"type\": \"Default\", \"cancel\": true, \"refill\": false, \"service\": \"4292\", \"category\": \"Telegram\", \"dripfeed\": true}",
    params: string | null, // "{\"link\": \"https://t.me/flkjsdflldjflj/2\", \"email\": \"zeinell69@gmail.com\", \"isLaunch\": false, \"quantity\": 1000}",
    price: string, // "30.00",
    status: string | null, // "WAITING",
    bill_info: string | null, // "{\"amount\": {\"value\": \"30.00\", \"currency\": \"RUB\"}, \"billId\": \"01cc6629-7a81-4c46-bc8c-9205982a30cf\", \"payUrl\": \"https://oplata.qiwi.com/form/?invoice_uid=5c6d8a63-3d29-4ec9-8de1-0a4af2b94341\", \"siteId\": \"5s2dka-00\", \"status\": {\"value\": \"WAITING\", \"changedDateTime\": \"2023-02-26T19:16:38.027+03:00\"}, \"customer\": {\"email\": \"zeinell69@gmail.com\", \"phone\": \"79248733163\"}, \"customFields\": {\"apiClient\": \"php_sdk\", \"apiClientVersion\": \"0.2.2\"}, \"creationDateTime\": \"2023-02-26T19:16:38.027+03:00\", \"expirationDateTime\": \"2023-04-12T19:16:38.027+03:00\"}",
    panel_get_order_id_response: string | null, // "[]",
    panel_order_status_response: string | null, // "[]"
}

export default class WosApi {

    private static _baseUrl = process.env.REACT_APP_BACKEND_API_ORIGIN

    private static async fetchWithToken(url: string, options: RequestInit = {}): Promise<Response> {
        const headers = new Headers(options.headers);

        let authToken = this.getToken()
        if (authToken) {
            headers.append('Authorization', `Bearer ${authToken}`);
        }

        return await fetch(url, { ...options, headers });
    }

    public static setToken(token: string | null) {
        if (token) {
            sessionStorage.setItem("token", token);
        } else {
            sessionStorage.removeItem("token")
        }
    }

    public static getToken() {
        return sessionStorage.getItem("token")
    }

    public static async login(login: string, password: string) {
        let requestOptions: RequestInit = {
            method: 'POST',
            body: JSON.stringify({"login":login,"password":password}),
            redirect: 'follow'
        }
        let response = await fetch(this._baseUrl + '/login', requestOptions)
        let data = await response.json()
        // if (!data.ok) throw new Error(data.error)
        if (!data.ok) {
            alert("Неверный логин или пароль:" + login + "," + password)
            throw new Error(data.error)
        }
        return data.data
    }

    public static async getServices(whitelist: boolean = false, pageNum: number = 1): Promise<PaginatedData<Service>> {
        let response = await WosApi.fetchWithToken(this._baseUrl + '/service/all?whitelist=' + (whitelist ? 'true' : 'false') + '&page=' + pageNum)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async getWhitelist(): Promise<PaginatedData<WhitelistItem<number>>> {
        let response = await WosApi.fetchWithToken(this._baseUrl + '/whitelist/all')
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async addWhitelist(whitelist: WhitelistItem<undefined>) {
        let formdata = new FormData()
        formdata.append("panel_id", whitelist.panel_id.toString())
        formdata.append("service_id", whitelist.service_id.toString())
        if (typeof whitelist.new_name === 'string') formdata.append("new_name", whitelist.new_name.toString())
        if (typeof whitelist.new_rate === 'string') formdata.append("new_rate", whitelist.new_rate.toString())
        if (typeof whitelist.content === 'string') formdata.append("content", whitelist.content)
        if (whitelist.social_network_id !== null) formdata.append("social_network_id", whitelist.social_network_id.toString())
        let requestOptions: RequestInit = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/whitelist/', requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async updateWhitelist(whitelist: WhitelistItem<number>) {
        let formdata = new FormData()
        formdata.append("panel_id", whitelist.panel_id.toString())
        formdata.append("service_id", whitelist.service_id.toString())
        formdata.append("new_name", whitelist.new_name ? whitelist.new_name.toString() : "")
        formdata.append("new_rate", whitelist.new_rate ? whitelist.new_rate.toString() : "")
        formdata.append("content", whitelist.content ? whitelist.content : "")
        formdata.append("social_network_id",  whitelist.social_network_id ? whitelist.social_network_id.toString() : "")
        let requestOptions: RequestInit = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/whitelist/' + whitelist.id, requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async deleteWhitelist(whitelistId: number) {
        let requestOptions: RequestInit = {
            method: 'DELETE',
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/whitelist/' + whitelistId, requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async getOrders(params: { page?: number, bill_id?: string }): Promise<PaginatedData<Order<number>>> {
        let paramsStr = QueryString.stringify(params)
        let response = await WosApi.fetchWithToken(this._baseUrl + '/order/all?' + paramsStr)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }
 
    public static async updateBill(billId: string) {
        let response = await WosApi.fetchWithToken(this._baseUrl + '/order/update-payment-status?bill_id=' + billId)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }
 
    public static async forceBuy(billId: string) {
        let response = await WosApi.fetchWithToken(this._baseUrl + '/order/create-order-manual?bill_id=' + billId)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }
 
    public static async updateWosApiOrderStatus(billId: string) {
        let response = await WosApi.fetchWithToken(this._baseUrl + '/order/update-status?bill_id=' + billId)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async getApis(pageNum: number = 1): Promise<PaginatedData<Api<number>>> {
        let response = await WosApi.fetchWithToken(this._baseUrl + '/panel/all?page=' + pageNum)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async addApi(baseUrl: string, key: string) {
        let formdata = new FormData()
        formdata.append("base_url", baseUrl)
        formdata.append("api_key", key)
        let requestOptions: RequestInit = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/panel/create', requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async updateApi(api: Api<number>) {
        let formdata = new FormData()
        formdata.append("baseUrl", api.base_url)
        formdata.append("key", api.api_key)
        let requestOptions: RequestInit = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/panel/update/' + api.id, requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async deleteApi(id: number) {
        let requestOptions: RequestInit = {
            method: 'DELETE',
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/panel/delete/' + id, requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async updateApiBalance(id: number) {
        let response = await WosApi.fetchWithToken(this._baseUrl + '/panel/' + id + '/update-balance')
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async getSocialNetworks(pageNum: number = 1): Promise<PaginatedData<SocialNetwork<number>>> {
        let response = await WosApi.fetchWithToken(this._baseUrl + '/social/all?page=' + pageNum)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async addSocialNetwork(codename: string, name: string) {
        let formdata = new FormData()
        formdata.append("codename", codename)
        formdata.append("name", name)
        let requestOptions: RequestInit = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/social/create', requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async deleteSocialNetwork(id: number) {
        let requestOptions: RequestInit = {
            method: 'DELETE',
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/social/delete/' + id, requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async getPosts(pageNum: number = 1): Promise<PaginatedData<Post<number>>> {
        let response = await WosApi.fetchWithToken(this._baseUrl + '/post/?page=' + pageNum)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async addPost(post: Post<undefined>, image: File | undefined) {
        let formdata = new FormData()
        formdata.append("title", post.title.toString())
        formdata.append("content", post.content.toString())
        formdata.append("image", post.image.toString())

        // if (image) formdata.append("image", image, image.name)
        let requestOptions: RequestInit = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/post/', requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async updatePost(post: Post<number>, image: File | undefined) {
        let formdata = new FormData()
        formdata.append("title", post.title.toString())
        formdata.append("content", post.content.toString())
        formdata.append("image", post.image.toString())

        // if (image) formdata.append("image", image, image.name)
        let requestOptions: RequestInit = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/post/' + post.id, requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

    public static async deletePost(postId: number) {
        let requestOptions: RequestInit = {
            method: 'DELETE',
            redirect: 'follow'
        }
        let response = await WosApi.fetchWithToken(this._baseUrl + '/post/' + postId, requestOptions)
        let data = await response.json()
        if (!data.ok) throw new Error(data.error)
        return data.data
    }

}
